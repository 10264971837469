.arrowContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;

	& > .arrow {
		display: inline-block;
		width: 1rem;
		height: 1rem;
		border-bottom: 1px solid rgb(39, 38, 44);
		border-right: 1px solid rgb(39, 38, 44);
		transition: transform 1s;
		transform: rotate(45deg);
		
		&.arrowInverted {
			transform: rotate(225deg);
		}
	}
}