@import '../../../app/colors.scss';
@import './animations.scss';

.hamburgerContainer {
    padding-bottom: 1rem;
    .hamburger {
        cursor: pointer;
        width: 2.4rem;
        height: 2.4rem;
        position: relative;
        display: block;
        background: none;
        border: none;
        padding: 0;
        outline: none;
        & > div {
            height: 0.3rem;
            width: 100%;
            background: $color-light-blue;
            position: absolute;
            display: none;
        }

        &.menuIsHidden {
            & > div {
                display: block;

                &:nth-child(1) {
                    top: 25%;
                    animation: firstPinToOpen 0.6s both;
                }
                &:nth-child(2) {
                    top: 50%;
                    animation: secondPinToOpen 0.6s both;
                }
                &:nth-child(3) {
                    top: 75%;
                    animation: thirdPinToOpen 0.6s both;
                }
            }
        }

        &.menuIsVisible {
            & > div {
                display: block;

                &:nth-child(1) {
                    top: 25%;
                    animation: firstPinToClose 0.6s both;
                }
                &:nth-child(2) {
                    top: 50%;
                    animation: secondPinToClose 0.6s both;
                }
                &:nth-child(3) {
                    top: 75%;
                    animation: thirdPinToClose 0.6s both;
                }
            }
        }

        &.menuIsInDepth {
            & > div {
                display: block;

                &:nth-child(2) {
                    display: none;
                }
                &:nth-child(1) {
                    animation: firstPinToDepth 0.6s both;
                }
                &:nth-child(3) {
                    animation: thirdPinToDepth 0.6s both;
                }
            }
        }
    }
}
