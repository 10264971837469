@import '../../../app/colors.scss';

.circle {
	width: 2rem;
	height: 2rem;
	border-radius: 50%;
	background-color: $color-selected-active-state;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;

	& > span {
		font-size: 1.4rem;
		color: $color-white;
	}
}