@import "../../../app/variables.scss";

.headerContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 1rem 1.5rem 1rem 1rem;
	color: #fff;
	background: #911d8b;
	font-size: 1.6rem;

	@media screen and (min-width: $desktop-breakpoint) {
		padding: 1rem 2rem 1rem 1rem;
	}

	.leftContainer {
		display: flex;
		flex-direction: row;
		align-items: center;

		.backBtn {
			font-size: 2rem;
			font-weight: bold;
			color: #FFF;
			margin-right: 1.5rem; 
			cursor: pointer;
			transform: scaleX(-1);
	
			@media screen and (min-width: $desktop-breakpoint) {
				margin-right: 2rem;
			}
	
			&:after {
				font-family: "icomoon", sans-serif;
				content: "\E91F";
				font-size: 1.4rem;
			}
		}

		h1 {
			font-size: 1.6rem;
			line-height: 2.4rem;
			font-weight: normal;
			margin: 0;
		}
	}

	.onChangeLink {
		color: #fff;
		font-size: 1.6rem;
		font-weight: bold;
		text-decoration: underline;
		cursor: pointer;
		align-self: flex-end;
	}
}