@import '../../../app/colors.scss';

.dropDown {
    position: relative;
    display: inline-block;
    width: 100%;
    select {
        width: 100%;
        height: 4rem;
        padding: 1rem;
        padding-right: 3rem;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: #fff;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 451.847 451.847'%3E%3Cpath d='M225.923 354.706c-8.098 0-16.195-3.092-22.369-9.263L9.27 151.157c-12.359-12.359-12.359-32.397 0-44.751 12.354-12.354 32.388-12.354 44.748 0l171.905 171.915 171.906-171.909c12.359-12.354 32.391-12.354 44.744 0 12.365 12.354 12.365 32.392 0 44.751L248.292 345.449c-6.177 6.172-14.274 9.257-22.369 9.257z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: right 10px center;
        border: 1px solid #5ccbd7;
        &:disabled {
            background: #999;
            color: #fff;
        }
    }
    .placeholder {
        color: #999;
    }
}

.bottomIndent {
    margin-bottom: 1.4rem;
}

.textField {
    position: relative;
    display: inline-block;
    width: 100%;
    .textFieldLabel {
        display: block;
        font-size: 1.4rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
    }
    input {
        width: 100%;
        height: 4rem;
        padding: 1rem;
        padding-right: 3rem;
        border: 1px solid rgb(92, 203, 215);
    }
    input::placeholder {
        color: $color-placeholder-text;
    }
    .eyeIcon {
        position: absolute;
        top: 60%;
        right: 7px;
        transform: translateY(-50%);
    }
}

.textFieldWarning {
    input {
        border-color: $color-error-warning-text;
    }
}

.checkboxField {
    display: flex;
    width: 100%;
    align-items: center;
    input[type='checkbox'] {
        visibility: hidden;
        width: 0;
        height: 0;
    }
    .checkboxLabel {
        display: block;
        position: relative;
        padding-left: 45px;
        margin-bottom: 15px;
        cursor: pointer;

        input:checked ~ .checkMark {
            background-color: $color-selected-active-state;
        }

        input:checked ~ .checkMark:after {
            display: block;
        }

        .checkMark:after {
            left: 8px;
            bottom: 5px;
            width: 6px;
            height: 12px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        .checkMark:after {
            content: '';
            position: absolute;
            display: none;
        }

        .checkMark {
            position: absolute;
            top: 5px;
            left: 0;
            height: 21px;
            width: 23px;
            background-color: $color-light-grey;
            border: 1px solid;
            border-radius: 2px;
            border-color: $color-border-grey;
            transition: background-color 250ms cubic-bezier(0.4, 0, 0.23, 1);
        }
    }
}
