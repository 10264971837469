@import "../../../../app/colors.scss";

.customInputContainer {
	position: relative;
	width: 100%;
	height: 100%;

	> span {
		cursor: pointer;
		position: absolute;
		top: 50%;
		right: 1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $color-light-blue;
		transform: translateY(-50%);

		svg {
			display: block;
		}
	}

	> input {
		padding-right: 3.6rem;
		text-overflow: ellipsis;
	}
}
