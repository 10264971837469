@import "../../../app/colors.scss";
@import "../../../app/variables.scss";

:global {
	.react-datepicker-wrapper {
		width: 100%;
		height: 100%;
	}

	.react-datepicker__input-container {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;

		& input {
			width: 100%;
			height: 100%;
			border: none;
			padding: 1rem;
			font-size: 14px;
		}
	}

	.react-datepicker,
	.react-datepicker__month-container {
		width: 100%;
		z-index: 5;
	}

	.react-datepicker {
		border: none !important;
	}

	.react-datepicker__header.react-datepicker__header--custom {
		background-color: $color-white;
		border-bottom: 0;
		padding: 0;
	}

	.react-datepicker-popper[data-placement^="bottom"] {
		margin-top: 0 !important;
	}

	.react-datepicker-popper[data-placement^="top"] {
		margin-bottom: 0 !important;
	}

	.react-datepicker__day {
		width: 3.1rem !important;
		height: 3.1rem !important;
	}

	.react-datepicker__day:hover,
	.react-datepicker__day--selected {
		background-color: $color-selected-active-state !important;
		color: $color-white !important;
		border-radius: 0 !important;
	}

	.react-datepicker__day--keyboard-selected {
		background-color: transparent !important;
	}

	.react-datepicker__day--outside-month {
		color: #ccc !important;
	}

	.react-datepicker__input-time-container {
		display: none;
	}
}

.datePickerLabel {
	display: block;
	font-size: 1.4rem;
	font-weight: bold;
	margin-bottom: 0.5rem;
}

.datePickerWrap {
	position: relative;
	width: 100%;
	height: 100%;
	border: 1px solid $color-cta-block-and-keyline;

	@media screen and (max-width: $desktop-breakpoint) {
		position: initial;
	}
}

.datePickerHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 2.2rem 3.7rem 1.7rem;

	> div {
		font-size: 1.6rem;
		font-weight: bold;
		font-family: "MarkPro", sans-serif;
	}

	button {
		font-size: 2rem;
		font-weight: bold;
		color: $color-icon-link;
		margin-bottom: 0.2rem;
		border: none;
		background: transparent;

		:after {
			font-family: "icomoon", sans-serif;
			content: "\E91F";
			font-size: 1.4rem;
		}
	}
}

.datePickerPropper {
	right: -2px;
	left: 0 !important;
	background-color: $color-white;
	border: 1px solid $color-cta-block-and-keyline;
	z-index: 7;

	@media screen and (max-width: $desktop-breakpoint) {
		width: 100vw;
		min-height: 100vh;
		right: 0 !important;
		left: 0 !important;
		top: 0 !important;
		bottom: auto;
		transform: none !important;
		padding: 12rem 2rem 2rem 2rem;
	}
	&.datePickerPropperInline {
        width: 100%;
        min-height: auto;
        padding: 0;
    }
}

.datePickerInput {
	position: relative;
	width: 100%;
	height: 4rem;
	padding: 1rem;
	padding-right: 3rem;
	border: 1px solid $color-cta-block-and-keyline;
	outline: none;
}

.timePickerContainer {
	clear: both;
	border-top: 1px solid $color-ab-muted-1;
	padding: 3rem 0.5rem 1rem;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	.timeDropDown {
		width: 8rem;
		font-weight: bold;
		text-align: center;
		margin: 0 1rem 0 0;
		font-size: 1.2rem;

		& > div > div > div > div {
			text-overflow: unset;
		}

		&:first-child {
			margin: 0 0.5rem 0 0;
		}
	}
}

.journeyTimeOptions {
	display: flex;
	flex-direction: row;
	padding: 2rem 2rem 0;

	h2.nav {
		flex: 1;
		font-size: 1.6rem;
		display: inline-block;
		padding: 1rem;
		color: $color-text-link;
		margin-top: 0;
		text-align: center;
		background: $color-nav-heading-bg;
		cursor: pointer;
		&.navActive {
			color: $color-white;
			background: $color-selected-active-state;
			border-radius: 0px;
		}
	}
}
.headerMenu {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	margin: 0 8px;

	.timeButton {
		border: 1px solid $color-cta-block-and-keyline;
		color: $color-text-link;
		padding: 1rem;
		font-size: 1.2rem;
		background-color: $color-white;

		&.navActive {
			color: $color-white;
			background: $color-selected-active-state;
		}
	}

	.timeButton:active {
		background-color: $color-selected-active-state;
	}
}
