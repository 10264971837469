@import '../../../app/colors.scss';
@import '../../../app/variables.scss';

.infoModal {

	.infoModalContent {
		width: 100%;
		display: flex;
		position: relative;
		flex-direction: column;
		align-items: flex-start;
		padding: 3.3rem 2.8rem 3rem;
		background-color: $color-white;
		height: 100%;
		& > h3 {
			margin-top: 0;
		}
		& > p {
			margin-bottom: 4rem;
		}
		.infoModalControls {
			display: flex;
			width: 100%;
			justify-content: center;
			& > div {
				display: flex;
				& > button:first-child {
					margin-right: 2.5rem;
				}
			}
		}
		.infoModalButton {
			width: 100%;
		}
		.closeIcon {
			position: absolute;
			top: 2rem;
			right: 2rem;
		}
	}
}

@media screen and (min-width: $desktop-breakpoint) {
	.infoModal {
		height: unset;
		min-height: unset;
		.infoModalContent {
			width: 47.8rem;
			height: fit-content;
			.infoModalControls {
				justify-content: flex-end;
			}
			.infoModalButton {
				width: 60%;
				display: flex;
				align-items: center;
				align-self: center;
				justify-content: center;
				height: 4.8rem; // for ie11, min-height set for .button will overwrite it
			}
		}
	}
	.infoModalOverlay {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}