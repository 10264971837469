.closeIcon {
	cursor: pointer;
	width: 2.4rem;
	height: 2.4rem;
	> div {
		height: 2.4rem;
		width: 0.2rem;
		transform: rotate(45deg);
		margin-left: 1rem;
		margin-top: -0.1rem;
		background: #686c71;
		> div {
			height: 2.4rem;
			width: 0.2rem;
			background-color: #686c71;
			transform: rotate(90deg);
		}
	}
}
