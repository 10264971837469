@keyframes firstPinToClose {
	0% {
		transform: rotateZ(0deg);
		top: 25%;
		height: 0.3rem;
	}
	20% {
		transform: rotateZ(0deg);
		height: 0.2rem;
	}
	100% {
		transform: rotateZ(45deg);
		top: 50%;
		height: 0.2rem;
	}
}

@keyframes secondPinToClose {
	0% {
		opacity: 1;
	}
	50% {
		height: 0.2rem;
	}
	85% {
		height: 0.1rem;
	}
	100% {
		opacity: 0;
	}
}

@keyframes thirdPinToClose {
	0% {
		transform: rotateZ(0deg);
		top: 75%;
		height: 0.3rem;
	}
	20% {
		transform: rotateZ(0deg);
		height: 0.2rem;
	}
	100% {
		transform: rotateZ(-45deg);
		top: 50%;
		height: 0.2rem;
	}
}

@keyframes firstPinToOpen {
	0% {
		transform: rotateZ(45deg);
		top: 50%;
		height: 0.2rem;
	}
	60% {
		transform: rotateZ(0deg);
		top: 50%;
		height: 0.2rem;
	}
	100% {
		transform: rotateZ(0deg);
		top: 25%;
		height: 0.3rem;
	}
}

@keyframes secondPinToOpen {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes thirdPinToOpen {
	0% {
		transform: rotateZ(-45deg);
		top: 50%;
		height: 0.2rem;
	}
	60% {
		transform: rotateZ(0deg);
		top: 50%;
		height: 0.2rem;
	}
	100% {
		transform: rotateZ(0deg);
		top: 75%;
		height: 0.3rem;
	}
}

@keyframes firstPinToDepth {
	0% {
		transform: rotateZ(45deg);
		top: 50%;
	}
	100% {
		transform: rotateZ(45deg);
		top: 75%;
		width: 75%;
		height: 0.2rem;
	}
}

@keyframes thirdPinToDepth {
	0% {
		transform: rotateZ(-45deg);
		top: 50%;
	}
	100% {
		transform: rotateZ(-45deg);
		top: 25%;
		width: 75%;
		height: 0.2rem;
	}
}