@import "./animations.scss";
.loadingIcon {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 2rem auto;
	width: 5rem;
	> div {
		background: #777;
		width: 1.2rem;
		height: 1.2rem;
		border-radius: 50%;
		&:nth-child(1) {
			animation: animateLoadingIcon 0.9s both infinite;
		}
		&:nth-child(2) {
			animation: animateLoadingIcon 0.9s both infinite;
			animation-delay: 0.3s;
		}
		&:nth-child(3) {
			animation: animateLoadingIcon 0.9s both infinite;
			animation-delay: 0.6s;
		}
	}
}
