.dropDown {
	& > div {
		border-radius: 0;
		border: 1px solid rgb(92, 203, 215) !important;
	}
}

:global {
	.passenger-type__option--is-selected::after {
		content: '';
		background-image: url('../../../app/images/checkmark.svg');
		width: 1.5rem;
		height: 1.1rem;
		position: absolute;
		top: 1.2rem;
		right: 2rem;
	}
}