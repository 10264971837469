@import "../../../app/colors.scss";

.button {
	background: $color-cta-block-and-keyline;
	color: $color-text-link;
	min-height: 4.8rem;
	border: 1px solid $color-cta-block-and-keyline;
	font-size: 1.6rem;
	font-weight: bold;
	padding: 0 4rem;
	outline: none;
	
	&:hover,
	&:focus {
		background: $color-hover;
		color: $color-text-link;
		border: 1px solid $color-hover;
	}
	&:active {
		background: $color-selected-active-state;
		color: #fff;
		border: 1px solid $color-selected-active-state;
	}
	&[disabled] {
		color: $color-hint-text;
		background: #dddedf;
		border: 1px solid #dddedf;
	}
	&[data-secondary="true"] {
		background: #fff;
		border: 1px solid $color-cta-block-and-keyline;
		color: $color-text-link;
		&:hover {
			border: 1px solid $color-hover;
		}
		&:active {
			border: 1px solid $color-selected-active-state;
		}
		&[disabled] {
			color: $color-hint-text;
			border: 1px solid $color-hint-text;
		}
	}
}

.linkButton {
	display: flex;
	align-items: center;
	justify-content: center;
	background: transparent;
	border: none;
	min-height: fit-content;
	min-width: fit-content;
	padding: 0;
	&:hover,
	&:focus {
		background: transparent;
		border: none;
		outline: none;
	}
	&:active {
		color: $color-text-link;
		border: none;
	}

	&[disabled] {
		color: $color-hint-text;
		background: none;
		border: none;
	}
}
