@import "../../../app/colors.scss";

.linkContainer {
	color: $color-text-link;
	font-weight: bold;
	font-size: 1em;

	& > div {
		display: inline-block;
	}

	& > a {
		text-decoration: none;
		color: $color-text-link;
	}

	.arrow {
		margin-top: 0.5rem;
		margin-left: 0.5rem;

		svg {
			transform: rotate(-90deg);
		}
		
	}
}