@mixin no-select {
	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Safari */
	-khtml-user-select: none;
	/* Konqueror HTML */
	-moz-user-select: none;
	/* Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;
	/* Non-prefixed version, currently supported by Chrome and Opera */
}
.caretButton {
	cursor: pointer;
	@include no-select;
	display: flex;
	flex-direction: row;
	align-items: center;
	> span:nth-child(2) {
		margin-left: 0.5rem;
		display: inline-block;
		height: 1rem;
		width: 1rem;
		transform: rotate(0);
		svg {
			position: absolute;
			top: 0;
			left: 0;
		}
	}
	&[data-isopen="true"] {
		> span:nth-child(2) {
			transform: rotate(180deg);
		}
	}
	&[data-isopen="false"] {
		> span:nth-child(2) {
			transform: rotate(0);
		}
	}
}
