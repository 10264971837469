@import "../../../app/colors.scss";
@import "../../../app/variables.scss";

@mixin truncateLines {
	display: block;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.promoList {
	display: flex;
	background: $color-white;
	max-width: 120rem;
    margin: 1rem auto;

	&.simple {
		max-width: 121.5rem;
		justify-content: space-between;
		background: $color-light-grey;
		flex-direction: column;
		margin: 4rem auto;
	}

	&.stacked {
		flex-direction: column;
	}

	&.carousel {
		padding: 0 1rem;
	}

	&.linkList {
		background: none;
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 1.5rem;
		padding: 2rem;
	}
}

.textInfo {
	padding: 0 2rem;

	h2 {
		@include truncateLines;
	}
}

@media (max-width: $desktop-breakpoint) {
	.promoList{
		&.linkList {
			grid-template-rows: auto !important;
		}
	}
}

@media screen and (min-width: $desktop-breakpoint) {
	.promoList {
		&.simple {
			flex-direction: row;
			max-height: 54rem;
		}
		&.carousel {
			padding: 0 4rem;
		}
		&.linkList {
			padding: 0;
			grid-template-columns: 1fr 1fr;
			max-width: 120rem;
			margin: 0 auto;
		}
	}

	.textInfo {
		margin: 0 auto;
		max-width: 120rem;
		padding: 0;
		margin-bottom: 2rem;
		overflow: hidden;

		h2 {
			font-size: 2.8rem;
			line-height: 3.6rem;
		}
	}
}
