@import '../../../app/variables.scss';
@import '../../../app/colors.scss';

.editorContainer {
    padding: 2rem;
    max-width: 120rem;
    margin: 0 auto;
    position: relative;
    overflow-x: hidden;

    .tableWrapper {
        overflow-x: auto;
    }

    table {
        font-family: 'MarkPro', sans-serif;
        border-collapse: collapse;
        border: none;

        thead {
            font-weight: bold;
            background: $color-nav-heading-bg;
        }
    }

    & > h2:first-child {
        margin-top: 0;
    }

    img {
        max-width: 100%;
        height: auto;

        &.alignRight {
            float: right;
        }

        &.alignLeft {
            float: right;
        }

        &.alignCenter {
            display: block;
            margin: 0 auto;
        }
    }
}

@media screen and (max-width: $ipad-breakpoint) {
    .editorContainer {
        padding: 0;
    }
}

@media screen and (max-width: $mobile-breakpoint) {
    .editorContainer {
        .tableWrapper {
            &::-webkit-scrollbar {
                width: 0;
                background: transparent;
            }
        }

        table,
        thead,
        tbody,
        th,
        td,
        tr {
            display: block;
        }

        tr {
            margin-bottom: 20px;
            border: 1px solid #c6c6c6;

            th {
                background: $color-nav-heading-bg;
                border: none;
                padding: 10px;
                text-align: left;
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            td:nth-child(odd) {
                background: $color-nav-heading-bg;
            }

            td:nth-child(even) {
                background: $color-white;
            }
        }

        thead {
            position: absolute;
            visibility: hidden;
            opacity: 0;
        }

        td {
            display: flex;
            border: none;
            border-bottom: 1px solid #eee;
            position: relative;
            white-space: normal;
            text-align: left;
            padding: 0 !important;
            width: 100% !important;

            .mobileTableCellTextContainer {
                padding: 10px;
                max-width: 65%;
                word-break: break-word;
            }

            .mobileTableHeaderContainer {
                width: 35%;
                text-align: left;
                font-weight: bold;
                padding: 10px;
                border-right: 1px solid #c6c6c6;
            }
        }
    }
}

@media screen and (min-width: $desktop-breakpoint) {
    .editorContainer {
        padding: 2rem 0;

        table {
            td,
            th {
                border: 1px solid #c6c6c6;
            }

            tbody {
                tr {
                    &:nth-child(odd) {
                        background: $color-white;
                    }

                    &:nth-child(even) {
                        background: $color-nav-heading-bg;
                    }
                }
            }
        }
    }
}
