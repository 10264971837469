@import "../../../app/colors.scss";

.radioButton {
	color: #fff;
	font-size: 1.6rem;
	input[type="radio"] {
		opacity: 0;
		& + label {
			cursor: pointer;
			border: 1px dotted transparent;
			padding: 4px;
			color: #444;
			&:before {
				content: "";
				position: relative;
				top: 2px;
				display: inline-block;
				width: 1.6rem;
				height: 1.6rem;
				background: #fff;
				border-radius: 50%;
				margin-right: 1rem;
				border: 2px solid #54bbca;
			}
		}
		&:focus + label {
			border: 1px dotted #ccc;
		}
		&:checked + label {
			&:before {
				background: #54bbca;
				background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E");
				background-repeat: no-repeat;
				background-position: 0 0;
				color: #fff;
			}
		}
	}
}

.secondary {
	input[type="radio"] {
		position: absolute;
		& + label {
			&:before {
				border: 1px solid $icon-btn-blue;
			}
		}
		&:checked + label {
			&:before {
				background: none;
				border: 4px solid $color-selected-active-state;
			}
		}
	}
}
