.container {
	& > p {
		color: rgb(39, 38, 44);
		font-size: 18px;
		font-weight: bold;
		line-height: 24px;
	}
	.list {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;

		& :last-child {
			border-bottom: none;
		}
	}
}