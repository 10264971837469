@import "../../../app/colors.scss";
@import "../../../app/variables.scss";

@mixin truncateLines {
	display: block;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.linkContainer {
	display: block;
	&:hover,
	&:focus {
		text-decoration: none;
	}
}

.promoItem {
	text-decoration: none;
	color: inherit;

	&:hover {
		text-decoration: none;
		color: inherit;	
	}

	&.simple:not(.middle):not(.large) {
		max-width: 120rem;
		margin: 2rem auto 0;
		display: flex;
		flex-direction: column;

		&:not(:only-of-type) {
			flex-basis: calc(100% / 3);
		}

		.content {
			box-shadow: 0px 0px 2.5rem rgba($color-body-copy, 0.1);
			margin: -4rem 2rem 0 2rem;
			position: relative;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			p {
				font-size: 1.6rem;
			}
		}
		.imageContainer {
			max-height: 25rem;
			min-height: 25rem;
			overflow: hidden;
			img {
				min-height: 25rem;
			}
		}
	}
	&.middle {
		display: flex;
		flex-direction: column;
		background: $color-white;
		max-width: 120rem;
		margin: 4rem auto;
		&.stacked {
			padding: 0 3rem;
			margin: 1rem auto;
			&:first-child {
				margin-top: 0;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
		&.carousel {
			margin: 4rem auto;
			.content {
				padding: 0;
				.title {
					margin-top: 1rem;
				}
			}
			.imageContainer {
				img {
					max-height: 20rem;
				}
			}
		}
	}
	&.large {
		display: flex;
		flex-direction: column;
		max-width: 120rem;
		margin: 4rem auto;
		background: $color-light-grey;
		
		.content {
			position: relative;
			margin: -4rem 2rem 0 2rem;
			padding: 2rem 4rem;

			&:before {
				content: "";
				border: 0.5rem solid;
				border-radius: 50%;
				position: absolute;
				top: 50%;
				left: 1.5rem;
				transform: translateY(-50%);
			}

			&:after {
				content: "";
				border-top: 2px solid $color-body-copy;
				position: absolute;
				top: 50%;
				right: calc(100% - 2rem);
				width: 100%;
				transform: translateY(-50%);
			}

			.title {
				margin-bottom: 1.5rem;
			}

			a {
				margin-top: 7rem;
			}
		}
	}

	&.simple,
	&.middle,
	&.large {
		img {
			max-width: 100%;
			max-height: 25rem;
			object-fit: cover;
			object-position: center;
		}
		.content {
			background: $color-white;
			padding: 3rem;
			color: $color-grey;

			.title {
				margin-top: 0;
				font-size: 2.2rem;
				line-height: 2.6rem;
			}
		}
	}

	&.linkList {
		position: relative;

		&:nth-child(1),
		&:nth-child(5),
		&:nth-child(7) {
			grid-row: span 1;
		}

		.imageContainer {
			display: initial;
			& > div {
				display: initial;
			}
		}

		.content {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			position: absolute;
			right: 0;
			bottom: 2rem;
			background: $color-white;
			padding: 1.5rem;
			width: 30rem;
			min-height: 10rem;

			.title {
				margin-top: 0;
				font-weight: bold;
				font-size: 2.2rem;
				line-height: 2.5rem;
			}
			a {
				margin-top: 0;
				align-self: flex-start;
			}
		}

		img {
			transition: all 0.3s ease;
			width: 100%;
			height: 100%;
			object-fit: cover;
			max-height: 40rem;
		}
	}

	.content {
		.title {
			@include truncateLines;
		}
		a {
			margin-top: 1.5rem;
			font-weight: bold;
			color: $color-text-link;
			display: inline-block;
			border-bottom: 1px solid transparent;
			align-self: flex-start;

			&:after {
				content: "\e91f";
				font-family: "icomoon", sans-serif;
				font-size: 1rem;
				color: $color-cta-block-and-keyline;
				margin-left: 0.5rem;
			}

			&:hover {
				text-decoration: none;
				border-bottom-color: inherit;
			}
		}
		p {
			@include truncateLines;
			-webkit-line-clamp: 3;
		}
	}
}

@media screen and (min-width: $desktop-breakpoint) {
	.promoItem {
		&.simple:not(.middle):not(.large) {
			&:not(:only-of-type) {
				flex-basis: calc(100% / 3 - 1.5rem);
			}

			.content {
				.title {
					font-size: 1.8rem;
					line-height: 2.2rem;
				}
				p {
					font-size: 1.4rem;
				}
			}
		}
		&.middle {
			flex-direction: row-reverse;
			align-items: center;
			justify-content: space-between;

			&.stacked {
				&:nth-child(2n) {
					flex-direction: row;
					.content {
						padding-left: 12.5rem;
						padding-right: 3rem;
					}
				}
			}
			& > div:last-child {
				flex-basis: 40rem;
				box-sizing: content-box;
			}

			.content {
				padding-right: 12.5rem;
			}

			.imageContainer {
				flex: 0 1 58rem;
				img {
					max-height: 38.5rem;
				}
			}
			&.carousel {
				margin: 8rem auto;
				.content {
					position: relative;
					padding: 3rem 1.5rem 3rem 0;
					flex-shrink: 0;
					.title {
						margin-top: 0;
					}
				}
				.imageContainer {
					flex: 0 1 68rem;
					max-height: 45.5rem;
					overflow: hidden;
					img {
						max-height: 38.5rem;
					}
				}
			}
		}
		&.large {
			flex-direction: row-reverse;
			align-items: center;

			.imageContainer {
				flex: 0 1 87.5rem;
				margin-left: -11.6rem;
			}

			.content {
				flex: 0 1 55.5rem;
				margin: 0;
				padding: 6.5rem 11.5rem 6.5rem 7.5rem;

				&:before {
					left: 2rem;
				}
			}

			.imageContainer {
				img {
					max-height: 58rem;
				}
			}
		}

		&.simple,
		&.middle,
		&.large {
			.content {
				.title {
					font-size: 2.8rem;
					line-height: 3.6rem;
				}
			}
		}

		&.linkList {
			&:nth-child(1),
			&:nth-child(5),
			&:nth-child(7) {
				grid-row: span 2;
			}

			.content {
				width: 35rem;
			}

			img {
				max-height: none;
			}
		}
	}
}
